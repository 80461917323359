/* playfair-display-sc-400normal - all */
@font-face {
  font-family: 'Playfair Display SC';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Playfair Display SC Regular'),
    local('PlayfairDisplaySC-Regular'), 
    url('./files/playfair-display-sc-all-400.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/playfair-display-sc-all-400.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-sc-400italic - all */
@font-face {
  font-family: 'Playfair Display SC';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Playfair Display SC Italic'),
    local('PlayfairDisplaySC-Italic'), 
    url('./files/playfair-display-sc-all-400-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/playfair-display-sc-all-400-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-sc-700normal - all */
@font-face {
  font-family: 'Playfair Display SC';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Playfair Display SC Bold'),
    local('PlayfairDisplaySC-Bold'), 
    url('./files/playfair-display-sc-all-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/playfair-display-sc-all-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-sc-700italic - all */
@font-face {
  font-family: 'Playfair Display SC';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Playfair Display SC Bold Italic'),
    local('PlayfairDisplaySC-BoldItalic'), 
    url('./files/playfair-display-sc-all-700-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/playfair-display-sc-all-700-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-sc-900normal - all */
@font-face {
  font-family: 'Playfair Display SC';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Playfair Display SC Black'),
    local('PlayfairDisplaySC-Black'), 
    url('./files/playfair-display-sc-all-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/playfair-display-sc-all-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-sc-900italic - all */
@font-face {
  font-family: 'Playfair Display SC';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Playfair Display SC Black Italic'),
    local('PlayfairDisplaySC-BlackItalic'), 
    url('./files/playfair-display-sc-all-900-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/playfair-display-sc-all-900-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
